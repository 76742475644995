import React, { useEffect, useContext, useState, useRef } from 'react'
import axios from 'axios'
import { useAppState } from './context/AppContext.js'
import NotyfContext from "../../contexts/NotyfContext";
import { useGlobalState } from '../../hooks/useCustomization.js';
import { TextWithLineBreaks } from "../../components/Functions.js"
import { getRandomInterval, checkMediaType } from "../../components/Functions.js"
import { PageLoader, PageLoaderDots } from '../../components/Elements.js'
import BaseLayout from "../../components/BaseLayout"
import { domainConfig } from "../../assets/config.js"
import { FormComponent } from '../../Register.js'
import './assets/style.css'
import { AnimatePresence, motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Photobooth = () => {
    const { dataApplication } = useGlobalState();
    const { appState, dispatch, updateData } = useAppState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const notyf = useContext(NotyfContext);
    const [showQR, setShowQR] = useState(appState.apresentation.configs.form !== false && (dataApplication.visitante.json === null || (dataApplication.visitante.json && Object.keys(dataApplication.visitante.json).length === 0 && dataApplication.visitante.json.constructor === Object)) ? false : true);
    const formikRef = useRef(null);
    const [stopFetching, setStopFetching] = useState()

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const getData = () => {
        axios.get(`/api/eventos/photobooth?evento_id=${dataApplication.evento_id}&customizacao_id=${appState.customizacao_id}&visitante_id=${dataApplication.visitante.visitante_id}`)
            .then(function (response) {
                if (response.data.message && response.data.message[0].json) {
                    response.data.message = JSON.parse(response.data.message[0].json)
                }
                dispatch({
                    type: 'setUserHistory', payload: response.data.message
                });
            }).catch(function (error) {
                console.log(error)
                dispatch({
                    type: 'setUserHistory', payload: false
                });
            })
    }

    const handleDownload = async (props) => {
        try {
            if (Array.isArray(props.generatedImages)) {
                props.generatedImages.push(props.media)
                for (const url of props.generatedImages) {
                    await downloadFile(url); // Call download logic for each URL
                }
            } else {
                // Single URL case
                await downloadFile(props.media);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: 'Houve um erro, tente novamente',
                ripple: true,
                dismissible: true,
            });
            console.error('Download error:', error.message);
        }
    };

    const downloadFile = async (mediaUrl) => {
        try {
            const response = await fetch(mediaUrl, { mode: 'cors' });
            if (!response.ok) {
                throw new Error(`Network response was not ok for ${mediaUrl}`);
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${appState.titulo}' - '${mediaUrl.split('/').pop()}`;

            // Check for Safari
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isSafari) {
                // Use FileReader for Safari
                const reader = new FileReader();
                reader.onloadend = function () {
                    const dataUrl = reader.result;
                    const a = document.createElement('a');
                    a.href = dataUrl;
                    a.download = `${appState.titulo}' - '${mediaUrl.split('/').pop()}`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                };
                reader.readAsDataURL(blob);
            } else {
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error(`Error downloading file: ${mediaUrl}`, error);
            throw error; // Propagate the error to the caller
        }
    };

    function registerUser(values, actions) {
        let json = {}
        let newArray = { ...values }
        if (newArray) {
            if (newArray.authorization) {
                delete newArray.authorization
            }
            Object.entries(newArray).forEach(entry => {
                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
                json[entry[0]] = { 'label': field.label, 'value': entry[1] }
            })
        }
        json = JSON.stringify(json)
        axios.put(`/api/eventos/register`, {
            visitante_id: dataApplication.visitante.visitante_id,
            json: json
        })
            .then(function (response) {
                setShowQR(true)
            }).catch(function (error) {
                actions.setSubmitting(false)
                notyf.open({
                    type: "danger",
                    message: 'Houve um erro no registro',
                    ripple: true,
                    dismissible: true,
                });
            })
    }

    const ResultImage = () => {
        console.log(appState.userHistory)
        if (appState.userHistory.leonardoai) {
            if (appState.userHistory.processedImages?.length > 0) {
                setStopFetching(true)
                return (
                    <motion.div key={'swiperDiv'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.25 } }} exit={{ opacity: 0 }}>
                        <div className="vertically-centered py-3">
                            <Swiper
                                style={{
                                    '--swiper-navigation-color': '#fff',
                                    '--swiper-pagination-color': '#fff',
                                }}
                                slidesPerView={'auto'}
                                spaceBetween={10}
                                observer={true}
                                observeParents={true}
                                zoom={true}
                                navigation={true}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Zoom, Navigation, Pagination]}
                                className="mySwiper"
                            >
                                {appState.userHistory.processedImages.map((srcX, index) => (
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img class="w-100 h-100" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + srcX} alt={`generatedImage${index}`} />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div>
                            <button type="button" className="btn primaryColorText primaryColor mt-3" onClick={() => handleDownload(appState.userHistory)}>Download</button>
                        </div>
                    </motion.div>
                )
            } else {
                if (appState.userHistory.processedImages === false) {
                    setStopFetching(true)
                    return (
                        <motion.div key={'loadingDiv'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.25 } }} exit={{ opacity: 0 }}>
                            <div className="vertically-centered py-5" >
                                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
                                <p className="mb-0 mt-4">Houve um problema com a geração das suas imagens, solicite ajuda ao colaborador mais próximo!</p>
                            </div>
                        </motion.div>
                    )
                } else {
                    return (
                        <motion.div key={'loadingDiv'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.25 } }} exit={{ opacity: 0 }}>
                            <div className="vertically-centered py-5" >
                                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
                                <p className="mb-0 mt-4">Suas imagens estão sendo geradas, aguarde um momento...<br></br><br></br>Não saia da página!</p>
                            </div>
                        </motion.div>
                    )
                }
            }
        } else {
            setStopFetching(true)
            return (
                <motion.div key={'imageContainerDiv'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.25 } }} exit={{ opacity: 0 }}>
                    <div className="imageContainer mb-3">
                        {(checkMediaType(appState.userHistory.media) === 'image' ? (
                            <img src={appState.userHistory.media} alt="photocam" className="w-100 h-100" ></img>
                        ) : (
                            <video controls muted autoPlay src={appState.userHistory.media} className="w-100" alt="videocam" />
                        ))}
                    </div>
                    <div>
                        <button type="button" className="btn primaryColorText primaryColor mt-3" onClick={() => handleDownload(appState.userHistory)}>Download</button>
                    </div>
                </motion.div>
            )
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!stopFetching) {
                getData()
            }
        }, getRandomInterval(10000, 15000));
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, stopFetching])

    if (!appState.userHistory) {
        return (
            <BaseLayout>
                <div>
                    <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={'Houve um problema'}></TextWithLineBreaks></h5></div>
                    <div className="col"><p className="mb-0"><TextWithLineBreaks text={'Não foi encontrado nada para esse usuário'}></TextWithLineBreaks></p></div>
                </div>
            </BaseLayout>
        )
    } else {
        return (
            <AnimatePresence mode="wait" initial={false}>
                {showQR ? (
                    <BaseLayout key={'showQR'}>
                        <div className="mb-4">
                            {appState.result.title && appState.result.title !== '' && <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={appState.result.title}></TextWithLineBreaks></h5></div>}
                            {appState.result.description && appState.result.description !== '' && <div className="col"><p><TextWithLineBreaks text={appState.result.description}></TextWithLineBreaks></p></div>}
                        </div>
                        <ResultImage></ResultImage>
                    </BaseLayout>
                ) : (
                    <BaseLayout key={'showForm'}>
                        <div className="mb-4">
                            {appState.apresentation.register && appState.apresentation.register.title !== '' && <div className="col">
                                <h5 className="mb-3"><TextWithLineBreaks text={appState.apresentation.register.title}></TextWithLineBreaks></h5>
                            </div>}
                        </div>
                        <div className="box-full">
                            <FormComponent formikRef={formikRef} registerUser={registerUser} onSubmittingStateChange={setIsSubmitting}></FormComponent>
                            <div className="text-center mt-3">
                                <button className="btn primaryColor btn-lg primaryColorText" type="submit" disabled={isSubmitting} onClick={submitFormExternally}>
                                    {!isSubmitting ? dataApplication.customizacao.form.buttonText : <PageLoaderDots color={dataApplication.customizacao.primaryColorText} width="25" padding={0} />}
                                </button>
                            </div>
                        </div>
                    </BaseLayout>
                )}
            </AnimatePresence >
        )
    }
}

export default Photobooth