import React, { useEffect } from "react";
import * as Yup from "yup"
import axios from 'axios'
import { parse, isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import {
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from "react-table";
import { Table, Pagination, Row, Col, Form } from "react-bootstrap";
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSort,
    faSortUp,
    faSortDown,
    faGlobe
} from "@fortawesome/free-solid-svg-icons";
import {
    faFacebook,
    faWhatsapp,
    faInstagram,
    faLinkedin,
    faYoutube,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import generateExcel from "zipcelx";

export const TextWithLineBreaks = ({ text }) => {
    if (!text || typeof text !== "string") {
        return
    }
    const lines = text.split(/\\n|\n/);
    const formattedText = lines.map((line, index) => (
        <React.Fragment key={index}>
            {line}
            {index !== text.length - 1 && <br />}
        </React.Fragment>
    ));
    return <span>{formattedText}</span>;
}

export function redirectURL(open, url) {
    if (open === true) {
        window.open(url, '_blank')
    } else {
        window.location.replace(url)
    }
}

// Date handlers
export function validateDate(date) {
    const parsedDateTime = parse(date, "dd/MM/yyyy", new Date());
    const dateX = new Date(parsedDateTime);
    const result = isValid(dateX);
    if (result === true) {
        return true;
    }
    return false;
}

export function convertDate(date, format = "dd/MM/yyyy HH:mm") {
    const newDate = formatInTimeZone(date, "America/Sao_Paulo", format);
    return newDate
}


export function countStandAloneNumbers(str) {
    if (str === undefined) {
        str = ''
    }
    return str.replace(/\D/g, "")
}


export function validarCPF(cpf) {
    cpf = countStandAloneNumbers(cpf)
    // Verifica se o CPF possui 11 dígitos
    if (cpf.length !== 11) {
        return false;
    }

    // Verifica se todos os dígitos são iguais (CPF inválido, mas passaria na validação do primeiro dígito)
    const digitosIguais = [...new Set(cpf.split(''))].length === 1;
    if (digitosIguais) {
        return false;
    }

    // Calcula o primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = (soma * 10) % 11;
    let digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;

    // Verifica o primeiro dígito verificador
    if (parseInt(cpf.charAt(9)) !== digitoVerificador1) {
        return false;
    }

    // Calcula o segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    let digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;

    // Verifica o segundo dígito verificador
    if (parseInt(cpf.charAt(10)) !== digitoVerificador2) {
        return false;
    }

    return true; // CPF válido
}

async function checkVisitor(props, input_id, label, evento_id) {
    try {
        var response = await axios.get(
            `/api/eventos/checkVisitor?evento_id=${evento_id}&id=${input_id}&value=${props.originalValue}`
        );
        if (response.data.message > 0) {
            return props.createError({ message: label + " já cadastrado", });
        } else {
            return true
        }
    } catch (error) {
        console.error(error);
    }
}

async function checkVisitorData(props, evento_id, customizacao_id, t, visitanteID) {
    try {
        var response = await axios.get(
            `/api/eventos/checkVisitorData?evento_id=${evento_id}&customizacao_id=${customizacao_id}&email=${props.originalValue}`
        );

        if (response.data.message && response.data.message.visitante_id > 0) {
            if (response.data.message.status > 0) {
                return props.createError({ message: t('t49') });
            } else {
                if (visitanteID) {
                    visitanteID.current = response.data.message.visitante_id
                }
                return true
            }
        } else {
            return props.createError({ message: t('t50'), });
        }
    } catch (error) {
        console.error(error);
    }
}

export function schemaCreator(dataApplication, editValues, customizacao_id, t, visitanteID) {
    let json = {}
    dataApplication.customizacao.form.fields.forEach((value, key) => {
        let id = value.inputID.toString()
        if (dataApplication.mode === 2 && editValues && value.validation === 'email') {
            return ""
        }
        json[id] = getValidator(value, dataApplication, customizacao_id, t, visitanteID);
    });
    json['authorization'] = Yup.boolean().oneOf([true], "Aceite os termos para continuar");
    return Yup.object().shape(json);
}

function getValidator(value, dataApplication, customizacao_id, t, visitanteID) {
    return Yup.mixed().test("relatedFields", function (valueX) {
        if (value.dependencies && value.dependencies.inputID > 0) {
            const dependenciesValue = this.parent[value.dependencies.inputID]; // Access another field value
            if (dependenciesValue === value.dependencies.value) {
                return true;
            }
            value.return = true
            return true;
        } else {
            return switchValidation(value, dataApplication, customizacao_id, t, visitanteID)
        }
    }).concat(
        switchValidation(value, dataApplication, customizacao_id, t, visitanteID)
    );
}

function switchValidation(value, dataApplication, customizacao_id, t, visitanteID) {
    if (value.return) {
        return Yup.string()
    }
    switch (value.validation) {
        case 'text':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("text", function (valueX) {
                        return new Promise((resolve, reject) => {
                            resolve(checkVisitor(this, value.inputID, value.label, dataApplication.evento_id));
                        });
                    });
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("2500", "Máximo de 2500 caracteres")
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
            }
        case 'textBig':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("2500", "Máximo de 2500 caracteres")
                    .test("text", function (valueX) {
                        return new Promise((resolve, reject) => {
                            resolve(checkVisitor(this, value.inputID, value.label, dataApplication.evento_id));
                        });
                    });
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("2500", "Máximo de 2500 caracteres")
            } else {
                return Yup.string()
                    .max("2500", "Máximo de 2500 caracteres")
            }
        case 'email':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .email("Email inválido")
                    .test("email", function (valueX) {
                        // eslint-disable-next-line eqeqeq
                        if (!dataApplication.mode || dataApplication.mode == 1) {
                            return new Promise((resolve, reject) => {
                                resolve(checkVisitor(this, value.inputID, value.label, dataApplication.evento_id));
                            });
                        } else {
                            return new Promise((resolve, reject) => {
                                resolve(checkVisitorData(this, dataApplication.evento_id, customizacao_id, t, visitanteID));
                            });
                        }
                    });
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .email("Email inválido")
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
                    .email("Email inválido")
            }
        case 'cpf':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("CPF", "CPF inválido", function (valueX) {
                        if (!validarCPF(valueX)) {
                            return false
                        }
                        return new Promise((resolve, reject) => {
                            resolve(checkVisitor(this, value.inputID, value.label, dataApplication.evento_id));
                        });
                    });
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("CPF", "CPF inválido", function (valueX) {
                        if (!validarCPF(valueX)) {
                            return false
                        } else {
                            return true
                        }
                    })
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
            }
        case 'telefone':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("telefone", "Telefone inválido", function (valueX) {
                        if (countStandAloneNumbers(valueX).length > 9) {
                            return new Promise((resolve, reject) => {
                                resolve(checkVisitor(this, value.inputID, value.label, dataApplication.evento_id));
                            });
                        } else {
                            return false
                        }
                    });
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("telefone", "Telefone inválido", function (valueX) {
                        if (countStandAloneNumbers(valueX).length > 9) {
                            return true
                        } else {
                            return false
                        }
                    });
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
            }
        case 'date':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("date", "Data inválida", function (valueX) {
                        if (validateDate(valueX)) {
                            return new Promise((resolve, reject) => {
                                resolve(checkVisitor(this, value.inputID, value.label, dataApplication.evento_id));
                            });
                        } else {
                            return false
                        }
                    });
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("date", "Data inválida", function (valueX) {
                        if (validateDate(valueX)) {
                            return true
                        } else {
                            return false
                        }
                    });
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
                    .test("date", "Data inválida", function (valueX) {
                        if (validateDate(valueX)) {
                            return true
                        } else {
                            return false
                        }
                    });
            }
        case 'number':
            if (value.primary) {
                return Yup.number().typeError("Valor inválido, apenas números")
                    .required("Campo obrigatório")
                    .test("number", function (valueX) {
                        return new Promise((resolve, reject) => {
                            resolve(checkVisitor(this, value.inputID, value.label, dataApplication.evento_id));
                        });
                    })
                    .test('number', 'Máximo de 255 caracteres', function (valueX) {
                        if (valueX) {
                            if (valueX.toString().length <= 255) {
                                return true
                            }
                        }
                        return false
                    })
            } else if (value.required) {
                return Yup.number().typeError("Valor inválido, apenas números")
                    .required("Campo obrigatório")
                    .test('number', 'Máximo de 255 caracteres', function (valueX) {
                        if (valueX) {
                            if (valueX.toString().length <= 255) {
                                return true
                            }
                        }
                        return false
                    })
            } else {
                return Yup.number().typeError("Valor inválido, apenas números")
                    .test('number', 'Máximo de 255 caracteres', function (valueX) {
                        if (valueX) {
                            if (valueX.toString().length <= 255) {
                                return true
                            }
                        }
                        return false
                    })
            }
        case 'checkbox':
            if (value.allowMultipleChoice) {
                if (value.required) {
                    return Yup.array()
                        .test('required', '', function (valueX) {
                            if (valueX === undefined || valueX === "" || (!Array.isArray(valueX) || valueX.length === 0)) {
                                return this.createError({
                                    message: "Campo obrigatório"
                                });
                            } else {
                                if (value.allowMultipleChoiceMin && valueX.length < value.allowMultipleChoiceMin) {
                                    return this.createError({
                                        message: `Selecione no mínimo ${value.allowMultipleChoiceMin} alternativas`
                                    });
                                } else if (value.allowMultipleChoiceMax && valueX.length > value.allowMultipleChoiceMax) {
                                    return this.createError({
                                        message: `Limite de ${value.allowMultipleChoiceMax} alternativas`
                                    });
                                } else {
                                    return true;
                                }
                            }
                        });
                } else {
                    return Yup.array()
                        .test('required', '', function (valueX) {
                            if (value.allowMultipleChoiceMin && valueX.length < value.allowMultipleChoiceMin) {
                                return this.createError({
                                    message: `Selecione no mínimo ${value.allowMultipleChoiceMin} alternativas`
                                });
                            } else if (value.allowMultipleChoiceMax && valueX.length > value.allowMultipleChoiceMax) {
                                return this.createError({
                                    message: `Limite de ${value.allowMultipleChoiceMax} alternativas`
                                });
                            } else {
                                return true;
                            }
                        });
                }
            } else {
                if (value.required) {
                    return Yup.string()
                        .test('required', '', function (valueX) {
                            if (valueX === undefined || valueX === "") {
                                return this.createError({
                                    message: "Campo obrigatório"
                                });
                            } else {
                                return true;
                            }
                        });
                } else {
                    return Yup.string()
                }
            }

        default:
            return ""
    }
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    // const count = preFilteredRows.length;
    return (
        <Form.Control
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={``}
            className="mt-2"
        />
    );
}

export function filterTableHandle(element) {
    let maxLength = 40;
    let truncatedString = element.label.length > maxLength
        ? element.label.slice(0, maxLength - 3) + "..."
        : element.label;
    switch (element.type) {
        case 'textarea':
        case 'input':
            return {
                Header: truncatedString,
                accessor: element.label,
                filter: "includes",
            }
        case 'number':
            return {
                Header: truncatedString,
                accessor: element.label,
                Filter: NumberRangeColumnFilter,
                filter: "between",
            }
        case 'select':
            return {
                Header: truncatedString,
                accessor: element.label,
                Filter: SelectColumnFilter,
                filter: "includes",
            }
        case 'checkbox':
            return {
                Header: truncatedString,
                accessor: element.label,
                Filter: SelectColumnFilter,
                filter: "includes",
            }
        default:
            break;
    }
}

export function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div className="d-flex mt-2">
            <Form.Control
                value={filterValue[0] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: "110px",
                }}
            />
            <span className="mx-2 mt-1">to</span>
            <Form.Control
                value={filterValue[1] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: "110px",
                }}
            />
        </div>
    );
}

export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);
    return (
        <Form.Select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </Form.Select>
    );
}

export const dateFilter = (rows, id, filterValue) => {
    return rows.filter(row => {
        const rowDate = new Date(row.values[id]);
        const filterDate = new Date(filterValue);

        // Example: return rows where row date is the same as the filter date
        return rowDate.toDateString() === filterDate.toDateString();
    });
};

export const ColumnSortingTable = ({
    columns,
    data,
    getCellProps,
    title,
    subTitle,
    modal = false,
    setTriggerExport,
    triggerExport,
}) => {
    const filterTypes = React.useMemo(
        () => ({
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0 },
            sortable: false,
            autoResetPage: false,
            autoResetFilters: false,
            autoResetSortBy: false
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    function getHeader(column) {
        const isReactElement = React.isValidElement(column.Header);

        if (isReactElement) {
            return [
                {
                    value: "",
                    type: "string",
                },
            ];
        }
        if (column.totalVisibleHeaderCount === 1) {
            return [
                {
                    value: column.Header,
                    type: "string",
                },
            ];
        } else {
            const span = [...Array(column.totalVisibleHeaderCount - 1)].map((x) => ({
                value: "",
                type: "string",
            }));
            return [
                {
                    value: column.Header,
                    type: "string",
                },
                ...span,
            ];
        }
    }

    function getExcel() {
        const config = {
            filename: title,
            sheet: {
                data: [],
            },
        };

        const dataSet = config.sheet.data;

        // review with one level nested config
        // HEADERS
        headerGroups.forEach((headerGroup) => {
            const headerRow = [];
            if (headerGroup.headers) {
                headerGroup.headers.forEach((column) => {
                    headerRow.push(...getHeader(column));
                });
            }
            dataSet.push(headerRow);
        });

        // FILTERED ROWS
        if (rows.length > 0) {
            rows.forEach((row) => {
                const dataRow = [];
                Object.values(row.values).forEach((value) => {
                    dataRow.push({
                        value,
                        type: typeof value === "number" ? "number" : "string",
                    })
                }
                );
                dataSet.push(dataRow);
            });
        } else {
            dataSet.push([
                {
                    value: "No data",
                    type: "string",
                },
            ]);
        }
        return generateExcel(config);
    }

    useEffect(() => {
        if (triggerExport) {
            getExcel();
            setTriggerExport(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerExport]);

    return (
        <>
            <Table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                    {column.canSort && (
                                        <span onClick={(e) => {
                                            e.stopPropagation(); // Prevent click from bubbling up to the th
                                            column.toggleSortBy(); // Manually toggle the sort
                                        }}>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                                                ) : (
                                                    <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                                                )
                                            ) : (
                                                <FontAwesomeIcon icon={faSort} className="ms-2" />
                                            )}
                                        </span>
                                    )}
                                    <div>
                                        {column.canFilter ? column.render("Filter") : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? (
                        page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps([{}, getCellProps(cell)])}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    ) : (
                        // Display a message or a row when there are no data
                        <tr>
                            <td className="py-4" colSpan={columns.length} style={{ textAlign: "center" }}>
                                Não foi encontrado nenhum resultado
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Row className="text-start">
                <Col md="6">
                    <span className="mx-2">
                        Página{" "}
                        <span>
                            {pageIndex + 1} de {pageOptions.length}
                        </span>
                    </span>
                    <span className="ms-3 me-2">Mostrar:</span>
                    <Form.Select
                        className="d-inline-block w-auto"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col md="6">
                    <Pagination className="float-end">
                        <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Prev
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Next
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        />
                        <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        />
                    </Pagination>
                </Col>
            </Row>
        </>
    );
};

export function checkMediaType(url) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const extension = url.split('.').pop().toLowerCase();

    if (imageExtensions.includes(extension)) {
        return 'image';
    } else {
        return 'video';
    }
}

export function getRandomInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function findHighestValue(jsonData) {
    const data = jsonData;

    if (!Array.isArray(data) || data.length === 0) {
        return 50
    }

    let highestValue = data[0].value;

    for (const item of data) {
        const value = item.value;
        if (value > highestValue) {
            highestValue = value;
        }
    }

    return highestValue;
}

export const url = () => {
    const {
        search
    } = window.location
    let paramsURL = new URLSearchParams(search)
    let data = {
        token: paramsURL.get('token'),
        presenter: paramsURL.get('presenter'),
        preview: paramsURL.get('preview'),
        encryptedData: paramsURL.get('encryptedData'),
        iv: paramsURL.get('iv'),
    }
    return data
}


// Helper function to shuffle an array
export const shuffle = (array) => {
    const result = [...array];
    for (let i = result.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [result[i], result[j]] = [result[j], result[i]];
    }
    return result;
};

export const shuffleFixedQuestionIds = (filteredArray, filteredOutItems) => {
    console.log(filteredArray)
    console.log(filteredOutItems)
    // Identify which elements in the filtered array are fixed by matching question_ids
    const fixedElements = filteredArray.reduce((acc, item, index) => {
        if (filteredOutItems.includes(item.question_id)) {
            acc[index] = item;
        }
        return acc;
    }, {});

    console.log('Fixed Elements:', fixedElements);

    // Shuffle the elements that are not in fixed indexes
    const elementsToShuffle = filteredArray.filter((_, index) => !fixedElements.hasOwnProperty(index));
    console.log('Elements to Shuffle:', elementsToShuffle);

    for (let i = elementsToShuffle.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [elementsToShuffle[i], elementsToShuffle[j]] = [elementsToShuffle[j], elementsToShuffle[i]];
    }

    // Reintegrate fixed elements into their original positions in the result array
    const result = Array(filteredArray.length).fill(null); // Create an array with the same length as filteredArray

    // Place fixed elements first
    Object.keys(fixedElements).forEach(index => {
        result[index] = fixedElements[index];
    });

    console.log('placed elements:', result)

    // Fill in the remaining shuffled elements
    for (let i = 0; i < result.length; i++) {
        if (result[i] === null) {
            result[i] = elementsToShuffle.shift();
        }
    }
    console.log('result:', result)

    return result;
};

export const convertBlobToMP3 = async (audioBlob) => {
    const ffmpeg = new FFmpeg(); // Use new instead of createFFmpeg()

    // Load FFmpeg with the previous arguments now moved to the load method
    await ffmpeg.load();

    // Write the blob to FFmpeg's FS (Now directly using writeFile method)
    await ffmpeg.writeFile('input.webm', await fetchFile(audioBlob));

    // Convert the audio file to MP3 format (Using exec instead of run)
    await ffmpeg.exec(['-i', 'input.webm', '-b:a', '192k', 'output.mp3']);

    // Read the MP3 file from FFmpeg's FS (Now directly using readFile method)
    const mp3Data = await ffmpeg.readFile('output.mp3');

    // Convert the MP3 data to a Blob
    const mp3Blob = new Blob([mp3Data.buffer], { type: 'audio/mp3' });

    return mp3Blob;
};

export const convertBlobToMp4 = async (videoBlob, imageUrl) => {
    const ffmpeg = new FFmpeg({ log: true });
    // Load FFmpeg
    await ffmpeg.load();
    ffmpeg.on("log", ({ type, message }) => {
    })
    const videoArrayBuffer = await videoBlob.arrayBuffer();
    await ffmpeg.writeFile('input.webm', await fetchFile(new Uint8Array(videoArrayBuffer)));
    // Write the image blob to FFmpeg's filesystem
    const imageResponse = await fetch(imageUrl);
    if (!imageResponse.ok) {
        throw new Error('Failed to fetch image');
    }
    const imageArrayBuffer = await imageResponse.arrayBuffer();
    await ffmpeg.writeFile('overlay.png', new Uint8Array(imageArrayBuffer));

    // Convert the video file with an image overlay, excluding audio, and output as WebM
    await ffmpeg.exec([
        '-f', 'webm',
        '-i', 'input.webm',
        '-i', 'overlay.png',
        '-filter_complex', 'overlay',
        '-an', // Disable audio in the output
        'output.webm'
    ]);
    const outputVideoData = await ffmpeg.readFile('output.webm');
    console.log(outputVideoData)
    console.log(outputVideoData.buffer)
    // Convert the processed video data to a Blob
    const outputVideoBlob = new Blob([outputVideoData.buffer], { type: 'video/webm' });
    console.log(outputVideoBlob)
    // Clean up: Remove files from FFmpeg's virtual filesystem
    // ffmpeg.FS('unlink', 'input.webm');
    // ffmpeg.FS('unlink', 'overlay.png');
    // ffmpeg.FS('unlink', 'output.webm');
    console.log('done')
    return outputVideoBlob;
}

export function dataURItoBlob(dataURI) {
    // split the data URI into parts
    const parts = dataURI.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}

export function convertMilliseconds(milliseconds) {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    const remainingMilliseconds = milliseconds % 1000;

    return {
        minutes: String(minutes).padStart(2, '0'),
        seconds: String(seconds).padStart(2, '0'),
        milliseconds: String(remainingMilliseconds).padStart(3, '0')
    };
}

export const exerciseType = (exercise) => {
    switch (exercise) {
        case 1:
            return "Múltipla escolha";
        case 2:
            return "Enquete";
        case 3:
            return "Resultado";
        default:
            return ""
    }
};

export const handleShareLink = (url, notyf) => {
    navigator.clipboard.writeText(url);
    notyf.open({
        type: "success",
        message: "Link copiado para a área de transferência",
        ripple: true,
        dismissible: true,
    });
}

export function compare(a, b) {
    if (a.last_nom < b.last_nom) {
        return -1;
    }
    if (a.last_nom > b.last_nom) {
        return 1;
    }
    return 0;
}

export function convertColor(color) {
    let c = color.split("rgba(");
    c = c[1].split(")")
    return c[0]
}

export function qrcodeDownload(qrCodeRef, email, padding = 20) {
    const svg = qrCodeRef.current;
    const svgData = svg ? new XMLSerializer().serializeToString(svg) : '';

    const img = new Image();

    img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calculate canvas dimensions including padding
        const canvasWidth = img.width + 2 * padding;
        const canvasHeight = img.height + 2 * padding;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Add a white background with padding
        ctx.fillStyle = "white"; // Set the fill color to white
        ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the entire canvas with white

        // Draw the QR code on the canvas with padding
        ctx.drawImage(img, padding, padding);

        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = email + ".png";
        downloadLink.href = pngFile;
        downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
}

export function SocialMidiaIcons({ element, index }) {
    let iconMedia
    let url
    switch (element["name"]) {
        case 'facebook':
            iconMedia = faFacebook
            url = element['url']
            break;
        case 'instagram':
            iconMedia = faInstagram
            url = element['url']
            break;
        case 'youtube':
            iconMedia = faYoutube
            url = element['url']
            break;
        case 'linkedin':
            iconMedia = faLinkedin
            url = element['url']
            break;
        case 'twitter':
            iconMedia = faTwitter
            url = element['url']
            break;
        case 'website':
            iconMedia = faGlobe
            url = element['url']
            break;
        case 'whatsapp':
            iconMedia = faWhatsapp
            url = element['url']
            break;
        default:
            break;
    }
    return (
        <FontAwesomeIcon key={index + 'icon'} icon={iconMedia} className="mx-2 iconsHover" size={"xl"} onClick={() => redirectURL(true, url)} />
    )
}

export function isTransparent(color) {
    // Check if the color is in rgba format
    if (color.startsWith('rgba')) {
        // Extract the alpha value from the rgba color string
        const alpha = parseFloat(color.split(',')[3].trim().replace(')', ''));
        // If the alpha value is 0, the color is fully transparent
        return alpha === 0;
    } else if (color.startsWith('rgb')) {
        // For rgb format, there is no alpha value, so it's not transparent
        return false;
    } else {
        // The color format is neither rgb nor rgba
        throw new Error('Unsupported color format');
    }
}
