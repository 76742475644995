import React, { useEffect, useState } from 'react';
import { useAppState } from '../context/AppContext.js'
import { TextWithLineBreaks } from "../../../components/Functions.js"
import { domainConfig } from "../../../assets/config.js"
import { AnimatePresence, motion } from 'framer-motion';

const AdditionalAnswer = () => {
    const { appState, score: currentScore, handleNextStep } = useAppState();
    // eslint-disable-next-line no-unused-vars
    const [score, setScore] = useState(currentScore);
    const correctAnswer = appState.questionsPrepared[score.currentIndex].answers.find(answer => answer.isCorrect === true);

    return (
        <AnimatePresence mode={'wait'}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }} className='col-md-12 px-3' key={'chart'}>
                <p className="questionTitle"><b>Resposta:</b> <TextWithLineBreaks text={correctAnswer.text}></TextWithLineBreaks></p>
                <p><TextWithLineBreaks text={appState.questionsPrepared[score.currentIndex].additionalAnswer.text}></TextWithLineBreaks></p>
                {appState.questionsPrepared[score.currentIndex].additionalAnswer.image && appState.questionsPrepared[score.currentIndex].additionalAnswer.image !== '' && <div className="pb-3"><img className={"img-thumbnail"}
                    src={
                        domainConfig.imageServer +
                        "/customizacao/" +
                        appState.customizacao_id +
                        "/" +
                        appState.questionsPrepared[score.currentIndex].additionalAnswer.image
                    }
                    alt=""
                /></div>}
            </motion.div>
        </AnimatePresence>
    )
}

export default AdditionalAnswer