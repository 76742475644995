import React, { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import { useGlobalState } from './hooks/useCustomization';
import { domainConfig } from "./assets/config";
import Settings from "./components/Settings"

//Main
import { AuthProvider } from './contexts/LoginContext'
import { Applications, ApplicationsPresenter, NoApplications } from './Applications'
import { Register } from './Register'

// Jogo da memória
import { GameProvider as GameProviderMemoryGame } from './apps/01/context/AppContext'
import MemoryGame from './apps/01/Index'
import MemoryGameAdmin from './apps/01/Presenter'

// Quiz
import { GameProvider as GameProviderQuiz } from './apps/02/context/AppContext'
import Quiz from './apps/02/Index'
import QuizAdmin from './apps/02/Presenter'

// Perguntas e respostas
import { GameProvider as GameProviderQA } from './apps/03/context/AppContext'
import QA from './apps/03/Index'
import QAdmin from './apps/03/Presenter'

// Quadro de mensagems
import { GameProvider as GameProviderMessageBoard } from './apps/04/context/AppContext'
import MessageBoard from './apps/04/Index'
import MessageBoardAdmin from './apps/04/Presenter'

// Nuvem de palavras
import { GameProvider as GameProviderWordCloud } from './apps/05/context/AppContext'
import WordCloud from './apps/05/Index'
import WordCloudAdmin from './apps/05/Presenter'

// Sorteio
import { GameProvider as GameProviderPrizeDraw } from './apps/06/context/AppContext'
import PrizeDraw from './apps/06/Index'
import PrizeDrawAdmin from './apps/06/Presenter'

// Roleta
import { GameProvider as GameProviderRouletteDraw } from './apps/07/context/AppContext'
import RouletteDraw from './apps/07/Index'
import RouletteDrawAdmin from './apps/07/Presenter'

// Photobooth AI
import { GameProvider as GameProviderPhotoboothAI } from './apps/08/context/AppContext'
import PhotoboothAI from './apps/08/Index'
import PhotoboothAIAdmin from './apps/08/Presenter'

// Networking
import { GameProvider as GameProviderNetworking } from './apps/09/context/AppContext'
import Networking from './apps/09/Index'
import NetworkingAdmin from './apps/09/Presenter'

// Photobooth
import { GameProvider as GameProviderPhotobooth } from './apps/10/context/AppContext'
import Photobooth from './apps/10/Index'
import PhotoboothAdmin from './apps/10/Presenter'

export const Routes = () => {
    const { dataApplication, setBlockScreen } = useGlobalState();

    function handleResize() {
        if (window.innerWidth < 600) {
            setBlockScreen(true)
        } else {
            setBlockScreen(false)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        if (window.innerWidth < 600) {
            setBlockScreen(true)
        } else {
            setBlockScreen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <motion.div
            className="main"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            {dataApplication.preview && <Settings />}
            {dataApplication.customizacao.background.type === "video" && <video className="videoBackground" autoPlay loop muted playsInline src={`${domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.background.url}`}></video>}
            <AnimatePresence mode={'wait'} initial={true}>
                {getRoutes(dataApplication)}
            </AnimatePresence>
        </motion.div>
    );
}

const getRoutes = (dataApplication) => {
    if (dataApplication.presenter && dataApplication.currentApp === undefined) {
        return (
            <motion.div
                className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                key={'ApplicationsPresenter'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <ApplicationsPresenter />
            </motion.div>
        )
    }
    if ((dataApplication.visitante && !dataApplication.register) || dataApplication.presenter || dataApplication.mode === 2) {
        if (dataApplication.apps.length === 0) {
            return (
                <motion.div
                    className="routerContainer"
                    key={'NoApplications'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <NoApplications />
                </motion.div>
            )
        } else {
            if (dataApplication.currentApp >= 0) {
                let app = dataApplication.data.aplicativo_id
                switch (app) {
                    case 1:
                        return (
                            <GameProviderMemoryGame>
                                <motion.div
                                    className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                                    key={app}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    {dataApplication.presenter ? <MemoryGameAdmin /> : <MemoryGame />}
                                </motion.div>
                            </GameProviderMemoryGame>
                        )
                    case 2:
                        return (
                            <GameProviderQuiz>
                                <motion.div
                                    className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                                    key={app}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    {dataApplication.presenter ? <QuizAdmin /> : <Quiz />}
                                </motion.div>
                            </GameProviderQuiz>
                        )
                    case 3:
                        return (
                            <GameProviderQA>
                                <motion.div
                                    className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                                    key={app}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    {dataApplication.presenter ? <QAdmin /> : <QA />}
                                </motion.div>

                            </GameProviderQA>
                        )
                    case 4:
                        return (
                            <GameProviderMessageBoard>
                                <motion.div
                                    className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                                    key={app}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    {dataApplication.presenter ? <MessageBoardAdmin /> : <MessageBoard />}
                                </motion.div>

                            </GameProviderMessageBoard>
                        )
                    case 5:
                        return (
                            <GameProviderWordCloud>
                                <motion.div
                                    className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                                    key={app}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    {dataApplication.presenter ? <WordCloudAdmin /> : <WordCloud />}
                                </motion.div>
                            </GameProviderWordCloud>
                        )
                    case 6:
                        return (
                            <GameProviderPrizeDraw>
                                <motion.div
                                    className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                                    key={app}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    {dataApplication.presenter ? <PrizeDrawAdmin /> : <PrizeDraw />}
                                </motion.div>
                            </GameProviderPrizeDraw>
                        )
                    case 7:
                        return (
                            <GameProviderRouletteDraw>
                                <motion.div
                                    className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                                    key={app}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    {dataApplication.presenter ? <RouletteDrawAdmin /> : <RouletteDraw />}
                                </motion.div>
                            </GameProviderRouletteDraw>
                        )
                    case 8:
                        return (
                            <GameProviderPhotoboothAI>
                                <motion.div
                                    className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                                    key={app}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    {dataApplication.presenter ? <PhotoboothAIAdmin /> : <PhotoboothAI />}
                                </motion.div>
                            </GameProviderPhotoboothAI>
                        )
                    case 9:
                        return (
                            <GameProviderNetworking>
                                {dataApplication.presenter ?
                                    (
                                        <motion.div
                                            className={"routerContainerPresenter"}
                                            key={app}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}>
                                            <NetworkingAdmin />
                                        </motion.div>
                                    ) : (
                                        <AuthProvider>
                                            <motion.div
                                                className={"GameProviderNetworking routerContainer"}
                                                key={app}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}>
                                                <Networking />
                                            </motion.div>
                                        </AuthProvider>
                                    )
                                }
                            </GameProviderNetworking>
                        )
                    case 10:
                        return (
                            <GameProviderPhotobooth>
                                <motion.div
                                    className={dataApplication.presenter ? "routerContainerPresenter" : "routerContainer"}
                                    key={app}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    {dataApplication.presenter ? <PhotoboothAdmin /> : <Photobooth />}
                                </motion.div>
                            </GameProviderPhotobooth>
                        )
                    default:
                        return ""
                }
            } else {
                return (
                    <motion.div
                        className="routerContainer"
                        key={'Applications'}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <Applications />
                    </motion.div>
                )
            }
        }
    } else {
        return (
            <motion.div
                className="routerContainer"
                key={'Register'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <Register />
            </motion.div>
        )
    }
};