
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Dropdown, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faVolumeHigh,
    faVolumeXmark,
    faDisplay
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from '../context/AppContext.js'
import { useGlobalState } from '../../../hooks/useCustomization.js';
import NotyfContext from "../../../contexts/NotyfContext.js";
import Fullscreen from '../../../components/FullScreen.js'
import { domainConfig } from "../../../assets/config.js"
import { handleShareLink } from "../../../components/Functions.js"
import { redirectURL } from "../../../components/Functions.js"
import { QuizRankingModal } from '../Presenter.js'

// export const NavbarPlayer = React.memo(() => {
//     const { dataApplication, handleExit } = useGlobalState();
//     const { appState, start, isPlaying, setIsPlaying } = useAppState();
//     const [isFirstClick, setIsFirstClick] = useState(false);
//     const notyf = useContext(NotyfContext);
//     const audioRef = useRef(null);

//     function togglePlay() {
//         setIsPlaying(!isPlaying)
//         if (isPlaying) {
//             audioRef.current.pause()
//         } else {
//             audioRef.current.play()
//         }
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     const handleFirstClick = () => {
//         if (!isFirstClick) {
//             setIsFirstClick(true);
//         }
//     };

//     useEffect(() => {
//         if (audioRef.current) {
//             if (!isPlaying) {
//                 audioRef.current.volume = 0;
//             } else {
//                 audioRef.current.volume = 0.4;
//             }
//         }
//     }, [isPlaying])

//     useEffect(() => {
//         document.addEventListener('click', handleFirstClick);

//         return () => {
//             document.removeEventListener('click', handleFirstClick);
//         };
//     }, [isFirstClick, handleFirstClick]);

//     useEffect(() => {
//         if (!audioRef.current) {
//             if (appState.music.status) {
//                 audioRef.current = new Audio(domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.music.value);
//                 audioRef.current.loop = true;
//                 audioRef.current.autoplay = true;
//             }
//         } else {
//             if (isPlaying) {
//                 audioRef.current.play()
//             }
//         }
//         return () => {
//             if (audioRef.current) {
//                 audioRef.current.pause(); // Pause the sound
//             }
//         };
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [start, dataApplication.currentApp, dataApplication.visitante, isFirstClick])

//     return (
//         <>
//             <div className="horizontally-centered">
//                 <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="logo d-inline-flex"></img>
//             </div>
//             <div></div>
//             <div className="horizontally-centered">
//                 {appState.music.status && <button className="btn me-2 primaryColor primaryColorText" onClick={() => togglePlay()}>
//                     {isPlaying === true ? (
//                         <FontAwesomeIcon icon={faVolumeHigh} />) : (<FontAwesomeIcon icon={faVolumeXmark} />)}
//                 </button>}
//                 <Dropdown className="d-inline" align="end">
//                     <Dropdown.Toggle className="btn primaryColor primaryColorText" variant="" id="dropdown-autoclose-true">
//                         <FontAwesomeIcon icon={faBars} />
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu>
//                         <Fullscreen />
//                         <Dropdown.Item onClick={(() => handleShareLink(domainConfig.aplicativos + "/?token=" + dataApplication.token, notyf))}>Compartilhar link</Dropdown.Item>
//                         {(dataApplication.customizacao.form.reset || (dataApplication.currentApp >= 0 && dataApplication.apps.length > 0)) && <Dropdown.Item onClick={(() => handleExit())}>Voltar</Dropdown.Item>}
//                     </Dropdown.Menu>
//                 </Dropdown>
//             </div>
//         </>
//     )
// })

export const NavbarPresenter = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { showPresentation, setShowPresentation } = useAppState();
    const notyf = useContext(NotyfContext);

    return (
        <>
            <div className="horizontally-centered">
                <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="logo d-inline-flex"></img>
            </div>
            <div></div>
            <div className="horizontally-centered">
                <OverlayTrigger
                    placement={"bottom"}
                    overlay={<Tooltip>Ranking</Tooltip>}
                >
                    <button className="btn primaryColor primaryColorText pointer me-2" type="button" onClick={() => setShowPresentation(true)}>
                        <FontAwesomeIcon icon={faDisplay} className="primaryColorText" />
                    </button>
                </OverlayTrigger>
                <Dropdown className="d-inline" align="end">
                    <Dropdown.Toggle className="btn primaryColor primaryColorText" variant="" id="dropdown-autoclose-true">
                        <FontAwesomeIcon icon={faBars} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Fullscreen />
                        <Dropdown.Item onClick={(() => handleShareLink(domainConfig.aplicativos + "/?token=" + dataApplication.token, notyf))}>Compartilhar link</Dropdown.Item>
                        <Dropdown.Item onClick={(() => handleExit())}>Voltar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Modal show={showPresentation} fullscreen={true}>
                <div className="modalPresenter">
                    <div className="image-side"></div>
                    <div className="text-side">
                        <QuizRankingModal />
                    </div>
                </div>
            </Modal>
        </>
    )
}