import React, { useReducer, useEffect, useState, useContext, useRef } from 'react';
import { io } from 'socket.io-client';
import axios from 'axios'
import HelmetStyle from '../components/HelmetStyle.js'
import { shuffleFixedQuestionIds, selectQuestions, shuffle, convertMilliseconds } from "../../../components/Functions"
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"

let socket
const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      };
    case 'setContexto':
      return {
        ...state,
        contexto: action.payload,
      }
    case 'resetUserHistory':
      return {
        ...state,
        userHistory: {},
      }
    case 'setHistory':
      return {
        ...state,
        history: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    case 'setResult':
      return {
        ...state,
        finalResult: action.payload,
      }
    case 'prepareQuestions':
      let newOrder = [...state.questions]

      // Check if userHistory or userHistory.answers is empty or undefined
      let hasUserHistoryAnswers = state.userHistory && Array.isArray(state.userHistory.answers) && state.userHistory.answers.length > 0;


      // Convert fixedIndexesString into an array of original indexes (zero-based)
      let fixedIndexes = state.shuffleQuestionsArray
        ? state.shuffleQuestionsArray
          .split(',')
          .map(index => parseInt(index.trim(), 10) - 1) // Adjust to zero-based indexing
          .filter(index => !isNaN(index) && index >= 0 && index < newOrder.length)
        : []; // Fallback to an empty array if it doesn't exist

      // Extract question_ids of fixed elements from the original array
      const fixedQuestionIds = fixedIndexes.map(index => newOrder[index]?.question_id);

      // Separate fixed items (those to be removed)
      let filteredOutItems = fixedQuestionIds
        ? newOrder.filter(item =>
          fixedQuestionIds.some(answer => answer === item.question_id)
        )
        : [];

      // Add the original index to each filtered item
      filteredOutItems = filteredOutItems.map(item => ({
        ...item,
        originalIndex: newOrder.findIndex(newItem => newItem?.question_id === item.question_id)
      }));

      // Separate filtered items (those to be removed) only if userHistory has answers
      let filteredAnwsersOutItems = hasUserHistoryAnswers
        ? newOrder.filter(item =>
          state.userHistory.answers.some(answer => answer?.question_id === item.question_id)
        )
        : [];

      // Filter the main array only if userHistory has answers
      let filteredArray = newOrder.filter(item =>
        (!hasUserHistoryAnswers || !state.userHistory.answers.some(answer => answer?.question_id === item.question_id)) &&
        !fixedQuestionIds.some(answer => answer === item.question_id)
      );

      let result = Array(newOrder.length).fill(null);
      if (state.shuffleQuestions || (state.selectQuestions && state.selectQuestions.status)) {
        filteredArray = shuffle(filteredArray)
        if (filteredOutItems && filteredOutItems.length > 0) {
          Object.keys(filteredOutItems).forEach(index => {
            if (!filteredAnwsersOutItems.some(existingQuestion => existingQuestion?.question_id === filteredOutItems[index].question_id)) {
              result[filteredOutItems[index].originalIndex] = filteredOutItems[index];
            }
          });
        }
      }
      // Fill in shuffled elements into remaining spaces
      filteredAnwsersOutItems.forEach((element, index) => {
        const emptyIndex = result.indexOf(null);
        result[emptyIndex] = element;
      });

      // Fill in shuffled elements into remaining spaces
      filteredArray.forEach((element, index) => {
        const emptyIndex = result.indexOf(null);
        result[emptyIndex] = element;
      });

      if (state.selectQuestions && state.selectQuestions.status) {
        result = result.slice(0, state.selectQuestions.value);
      }

      result.forEach(elementA => {
        if (elementA.shuffleAnswers) {
          elementA.answers = shuffle(elementA.answers)
        }
      })

      return {
        ...state,
        questionsPrepared: result,
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication } = useGlobalState();
  const initialState = {
    ...dataApplication.data,
    questionsPrepared: {}, // Set act to 1 in the new initial state object
  };
  const [appState, dispatch] = useReducer(reducer, initialState);
  const [score, setScore] = useState(false)
  const [start, setStart] = useState(false)
  const [exerciseView, setExerciseView] = useState('answers')
  const loadingRef = useRef(false);
  const [dateUnix, setDateUnix] = useState(new Date())
  const [showAns, setShowAns] = useState(false)
  const [loadingQuestion, setLoadingQuestion] = useState(false)
  const [endStart, setEndStart] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)
  const [resetGame, setResetGame] = useState(0)
  const [showPresentation, setShowPresentation] = useState(false)
  const initialScore = {
    'currentIndex': 0, 'value': 0, 'totalCorrect': 0, 'time': 0,
    'answers': [{ order: 0, time: 0, value: 0, question_id: 0, exercise: 0 }]
  }

  //Presenter states
  const handleNextStep = () => {
    // If the function is already in progress, prevent further execution
    // Set loading back to false once the action is complete
    console.log(loadingRef.current)
    if (loadingRef.current) {
      if (score.currentIndex + 1 < appState.questionsPrepared.length) {
        setDateUnix(new Date());
        setShowAns(false);
        setScore(prevState => ({
          ...prevState,
          currentIndex: prevState.currentIndex + 1
        }));
        setStart(1);
      } else {
        setStart(6);
      }
      setTimeout(() => {
        setLoadingQuestion(false)
      }, 750);
      loadingRef.current = false
    }
  };

  const getHistory = async () => {
    try {
      const response = await axios.get(
        `/api/eventos/history/${dataApplication.evento_id}?customizacao_id=${appState.customizacao_id}`,
        { customizacao_id: appState.customizacao_id }
      );

      let history = response.data.message;
      history.forEach((historyItem) => {
        historyItem.timeX = historyItem.time
        let convertedTime = convertMilliseconds(historyItem.time);
        convertedTime =
          convertedTime['minutes'] +
          ':' +
          convertedTime['seconds'] +
          ':' +
          convertedTime['milliseconds'];
        historyItem.time = convertedTime;
        historyItem.score = historyItem.value
      });
      let data = sortRanking(history);
      dispatch({ type: 'setHistory', payload: data });

      return data; // Return the processed data
    } catch (error) {
      console.log(error);
      throw error; // Throw error to handle it outside
    }
  };

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?evento_id=' + dataApplication.evento_id + '&aplicativo_id=' + appState.aplicativo_id + '&customizacao_id=' + appState.customizacao_id, {
        autoConnect: true,
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        console.log('login')
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('getData', (result) => {
        let data = processParticipants(result.participants, result.history, appState, initialScore)
        dispatch({ type: 'setHistory', payload: data.history });
        dispatch({ type: 'setResult', payload: data.result });
      });
      socket.emit('login');
      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: 'prepareQuestions' });

    if (appState.userHistory && Object.keys(appState.userHistory).length > 0) {
      setScore({ 'currentIndex': appState.userHistory.currentIndex, 'value': appState.userHistory.value, 'totalCorrect': appState.userHistory.totalCorrect, 'time': appState.userHistory.time, 'answers': appState.userHistory.answers })
      let totalQuestions = appState.selectQuestions?.status ? appState.selectQuestions?.value : appState.questions.length
      if (appState.userHistory.answers.length >= totalQuestions) {
        // Game already done
        setStart(6)
      } else {
        // Continue game
        setStart(appState.noStartAnimation ? 1 : 0)
      }
    } else {
      setScore(initialScore)
      setStart(appState.noStartAnimation ? 1 : 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (resetGame > 0) {
      dispatch({ type: 'resetUserHistory' });
      dispatch({ type: 'prepareQuestions' });
      setExerciseView('answers')
      loadingRef.current = false
      setLoadingQuestion(false)
      setEndStart(false)
      setDateUnix(new Date())
      setShowAns(false)
      setScore({ 'currentIndex': 0, 'value': 0, 'totalCorrect': 0, 'time': 0, 'answers': [{ order: 0, time: 0, value: 0, question_id: 0, exercise: 0 }] })
      setStart(appState.noStartAnimation ? 1 : 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetGame])

  return (
    <AppContext.Provider value={{
      appState, dispatch, score, setScore, start, setStart, showPresentation, setShowPresentation, showAns, setShowAns,
      dateUnix, setDateUnix, exerciseView, setExerciseView, endStart, setEndStart, isPlaying, setIsPlaying, loadingRef,
      getHistory, setResetGame, initialScore, handleNextStep, loadingQuestion, setLoadingQuestion
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const sortRanking = (data) => {
  data.sort((a, b) => {
    const currentIndexA = a.currentIndex || 0;
    const currentIndexB = b.currentIndex || 0;

    const scoreA = a.value || 0;
    const scoreB = b.value || 0;

    const timeA = a.timeX || 0;
    const timeB = b.timeX || 0;

    if (currentIndexA !== currentIndexB) {
      return currentIndexB - currentIndexA;
    }
    if (scoreA !== scoreB) {
      return scoreB - scoreA;
    }
    if (timeA !== timeB) {
      return timeA - timeB;
    }
    return 0
  });
  return data;
};

const getData = () => {
  socket.emit('getData');
};

export {
  AppContext,
  GameProvider,
  getData
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(participants, history) {
  participants.forEach(participant => {
    history.forEach(historyItem => {
      if (participant.visitante_id === historyItem.visitante_id) {
        historyItem.participantDetails = JSON.parse(participant.json);
        Object.assign(historyItem, JSON.parse(historyItem.json));
        historyItem.timeX = historyItem.time
        let convertedTime = convertMilliseconds(historyItem.time);
        convertedTime = convertedTime['minutes'] + ':' + convertedTime['seconds'] + ':' + convertedTime['milliseconds']
        historyItem.time = convertedTime
        historyItem.score = historyItem.value
      }
    });
  });
  let sortedParticipants = sortRanking(history)
  return { 'history': sortedParticipants };
}