

import React, { useState, useEffect, useContext, useRef } from 'react'
import { formatInTimeZone } from "date-fns-tz";
import { Tabs, Tab, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap'
import axios from 'axios'
import { AnimatePresence, motion } from "framer-motion"
import { Camera } from "react-camera-pro";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import NotyfContext from "../../contexts/NotyfContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import {
    faArrowLeft,
    faCircle,
    faFileExport,
    faTrophy,
    faCamera,
    faMicrophone,
    faStop,
    faPlay,
    faRefresh,
    faMicrophoneLines,
    faFaceSurprise,
    faFaceSmile,
    faFaceMeh,
    faFaceAngry,
    faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import {
    faFaceSurprise as faFaceSurpriseRegular,
    faFaceSmile as faFaceSmileRegular,
    faFaceMeh as faFaceMehRegular,
    faFaceAngry as faFaceAngryRegular
} from "@fortawesome/free-regular-svg-icons";
import { NavbarPresenter } from './components/Navbar.js'
import { useGlobalState } from '../../hooks/useCustomization.js';
import { domainConfig } from "../../assets/config.js"
import { useAppState, getData, createVideo, saveVideo } from "./context/AppContext.js"
import { PageLoader } from '../../components/Elements.js'
import { TextWithLineBreaks, convertDate, ColumnSortingTable, filterTableHandle, SocialMidiaIcons, dataURItoBlob, convertBlobToMP3 } from "../../components/Functions.js"
import { FormComponent } from '../../Register.js'

const PhotoboothAIAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updateData } = useAppState();

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 15000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, appState.isConnected]);

    if (appState.participants) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="k2"
                                transition={true}
                                id="noanim-tab-example"
                                className="mb-4"
                            >
                                {/* <Tab eventKey="k1" title={`Configurações`}>
                                    <AnimatePresence mode="wait">
                                         <Painel></Painel>
                                    </AnimatePresence>
                                </Tab> */}
                                <Tab eventKey="k2" title={`Resultados (${appState.results.length})`}>
                                    <Resultados></Resultados>
                                </Tab>
                                <Tab eventKey="k3" title={`Participantes (${appState.participants.length})`}>
                                    <Participants></Participants>
                                </Tab>
                            </Tabs>
                        </div>
                    </motion.div >
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 600px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

// const Painel = () => {
//     const { appState, isPlaying } = useAppState();
//     const [showModal, setShowModal] = useState(false)
//     const [modalData, setModalData] = useState(false)
//     const [showResult, setShowResult] = useState(false)
//     const [modalResult, setModalResult] = useState(false)
//     const [fonts, setFonts] = useState([]);
//     const notyf = useContext(NotyfContext);


//     return (
//         <div className="row mx-0 mt-4">
//             <div className="col-md-12">
//                 <button className="btn primaryColor primaryColorText" onClick={() => updateVideoStatus()}>Sincronizar dados</button>
//             </div>
//         </div>
//     )
// }

const Resultados = () => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState();
    const [fields, setFields] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);

    useEffect(() => {
        let fieldsX = []
        fieldsX.push({
            Header: 'ID',
            accessor: 'id',
        })
        dataApplication.customizacao.form.fieldsToShow.forEach((element) => {
            dataApplication.customizacao.form.fields.forEach((element2) => {
                if (element === element2.inputID) {
                    fieldsX.push(filterTableHandle(element2))
                }
            });
        });
        fieldsX.push({
            Header: 'Expressão',
            accessor: 'expression',
        })
        fieldsX.push({
            Header: 'Imagem',
            accessor: 'image',
            Cell: (row) => {
                return <a href={row.value ? row.value : undefined}>{row.value}</a>
            }
        })
        fieldsX.push({
            Header: 'Audio',
            accessor: 'audio',
            Cell: (row) => {
                return <a href={row.value ? row.value : undefined}>{row.value}</a>
            }
        })
        fieldsX.push({
            Header: 'Status',
            accessor: 'status',
        })
        fieldsX.push({
            Header: 'Resultado',
            accessor: 'result_url',
            Cell: (row) => {
                return <a href={row.value ? row.value : undefined}>{row.value ? 'Acessar URL' : ''}</a>
            },
        })
        fieldsX.push({
            Header: 'Data de registro',
            accessor: 'data',
            Cell: (row) => {
                return convertDate(row.value)
            },
        })
        setFields(fieldsX)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (fields) {
        return (
            <div>
                <div className="tablePresenter">
                    <div className="text-center">
                        <OverlayTrigger overlay={<Tooltip>Atualizar</Tooltip>}>
                            <button
                                className="btn primaryColor primaryColorText exportButton mb-1"
                                onClick={() => {
                                    setTriggerExport(true);
                                }}
                            >
                                <FontAwesomeIcon className="vertically-centered cursor-pointer px-1 primaryColorT" size={"lg"} icon={faRefresh} />
                            </button>
                        </OverlayTrigger>
                    </div>
                    <ColumnSortingTable
                        columns={fields}
                        data={appState.results}
                        getCellProps={(cellinfo) => ({
                            style: {
                                textAlign: cellinfo.column.id === "data" ? "center" : "left",
                            },
                        })}
                        triggerExport={triggerExport}
                        setTriggerExport={setTriggerExport}
                    />
                </div>
            </div >
        );
    }

}

const Participants = () => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState();
    const [fields, setFields] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(false)

    const openParticipantHistory = (value) => {
        setShowModal(true);
        setModalData(value)
    }

    useEffect(() => {
        let fieldsX = []
        dataApplication.customizacao.form.fields.forEach((element) => {
            fieldsX.push(filterTableHandle(element))
        });
        fieldsX.push({
            Header: 'Data de registro',
            accessor: 'data_visitacao',
            Cell: (row) => {
                return convertDate(row.value)
            },
        })
        fieldsX.push({
            Header: <OverlayTrigger overlay={<Tooltip>Exportar</Tooltip>}>
                <button
                    className="btn primaryColor primaryColorText exportButton float-end"
                    onClick={() => {
                        setTriggerExport(true);
                    }}
                >
                    <FontAwesomeIcon className="vertically-centered cursor-pointer px-1 primaryColorT" size={"lg"} icon={faFileExport} />
                </button>
            </OverlayTrigger>,
            accessor: 'premios',
            disableSortBy: true,
            Filter: false,
            Cell: (cell) => {
                return (
                    <OverlayTrigger overlay={<Tooltip>Histórico</Tooltip>}>
                        <div>
                            <FontAwesomeIcon icon={faTrophy} size={'lg'} className="me-2 cursor-pointer primaryColorT" onClick={() => openParticipantHistory(cell.row.original)} />{cell.row.original.history.length}
                        </div>
                    </OverlayTrigger>
                );
            },
        })
        setFields(fieldsX)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (fields) {
        return (
            <div>
                <div className="tablePresenter">
                    <ColumnSortingTable
                        columns={fields}
                        data={appState.participants}
                        title={dataApplication.titulo}
                        getCellProps={(cellinfo) => ({
                            style: {
                                textAlign: cellinfo.column.id === "data_visitacao" || cellinfo.column.id === 'validado' || cellinfo.column.id === "premios" ? "center" : "left",
                            },
                        })}
                        triggerExport={triggerExport}
                        setTriggerExport={setTriggerExport}
                    />
                </div>
                <Modal show={showModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onHide={(() => setShowModal(false))}>
                        <Modal.Title>Histórico de premiação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                            if (field && modalData.participantDetails && modalData.participantDetails[field.inputID]) {
                                return (
                                    <p className="mb-0 modalDataFields" key={'modalDataFields' + index}>
                                        {modalData.participantDetails[field.inputID].label}: <span className="opacity-75">{modalData.participantDetails[field.inputID].value}</span>
                                    </p>
                                );
                            }
                            return null; // Handle cases where the field is not found (optional)
                        })}
                        <ParticipantHistoryModal modalData={modalData} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export const PhotoboothAIModal = () => {
    const { appState, setShowPresentation } = useAppState();
    const [step, setStep] = useState(0)
    const [formValues, setFormValues] = useState()
    const stepArray = appState.register ?
        [
            { 'stepOrder': 1, 'showOrder': 1, 'label': 'Registro' },
            { 'stepOrder': 2, 'showOrder': 2, 'label': 'Expressão' },
            { 'stepOrder': 3, 'showOrder': 3, 'label': 'Imagem' },
            { 'stepOrder': 4, 'showOrder': 4, 'label': 'Áudio' },
            { 'stepOrder': 5, 'showOrder': 5, 'label': 'Criação' },
            { 'stepOrder': 6, 'showOrder': 6, 'label': 'Resultado' }
        ] : [
            { 'stepOrder': 2, 'showOrder': 1, 'label': 'Expressão' },
            { 'stepOrder': 3, 'showOrder': 2, 'label': 'Imagem' },
            { 'stepOrder': 4, 'showOrder': 3, 'label': 'Áudio' },
            { 'stepOrder': 5, 'showOrder': 4, 'label': 'Criação' },
            { 'stepOrder': 6, 'showOrder': 5, 'label': 'Resultado' }
        ]

    function saveFormData(values, actions) {
        actions.setSubmitting(false);
        setFormValues(values)
        setStep((lastStep) => lastStep + 1)
    }

    function handleBackStep() {
        if (step === 2) {
            if (!appState.register) {
                setStep((lastStep) => lastStep - 2)
            } else {
                setStep((lastStep) => lastStep - 1)
            }
        } else {
            setStep((lastStep) => lastStep - 1)
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) { // Check if the "End" key was pressed
                setShowPresentation(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AnimatePresence mode="wait" initial={false}>
            <BaseLayout step={step} handleBackStep={handleBackStep} stepArray={stepArray}>
                <StepComponent key={'StepComponent' + step} step={step} setStep={setStep} formValues={formValues} setFormValues={setFormValues} saveFormData={saveFormData}></StepComponent>
            </BaseLayout>
        </AnimatePresence>
    )
}

const BaseLayout = ({ step, children, handleBackStep, stepArray }) => {
    const { dataApplication } = useGlobalState();

    switch (dataApplication.customizacao.layout.type) {
        default:
        case 1:
            return (
                <div className="layout-01">
                    <div className="logoBox">
                        <motion.div className={step === 0 || step > 4 ? "logoBoxHideButton" : ""} initial={{ opacity: 0 }} animate={{ opacity: step === 0 || step > 4 ? 0 : 1 }} exit={{ opacity: 0 }}>
                            <div className="backButton" onClick={() => handleBackStep()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                        </motion.div>
                        <div className="logoBoxChildMiddle">
                            <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo pb-0"></img>
                        </div>
                        <div className="logoBoxHideButton">
                            <div className="backButton"></div>
                        </div>
                    </div>
                    {step > 0 && <motion.div className="stepComponent-box mb-4" initial={{ display: 'none', opacity: 0 }} animate={{ display: 'block', opacity: 1 }} exit={{ opacity: 0 }}>
                        <ul className="stepComponent">
                            {stepArray.map((item, i) => (
                                <li key={'liComponent' + i}>
                                    <Step step={step} stepOrder={item.stepOrder} showOrder={item.showOrder} />
                                    <span className="label">{item.label}</span>
                                </li>
                            ))}
                        </ul>
                    </motion.div>}
                    <div className="default-box">
                        {children}
                    </div>
                    <div className="footer">
                        {dataApplication.customizacao.layout.footer.type === 'image' ?
                            (
                                <div>
                                    <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.layout.footer.url} width={dataApplication.customizacao.layout.footer.width} alt={'showFooterImg'}></img>
                                </div>
                            ) : (
                                <div className="baseColorText">
                                    {dataApplication.customizacao.layout.footer.socialMedia.length > 0 && dataApplication.customizacao.layout.footer.socialMedia.map((element, index) => (
                                        <SocialMidiaIcons key={'icons' + index} element={element} index={index} />
                                    ))}
                                </div>
                            )}
                    </div>
                </div>
            )
        case 2:
            return (
                <div className="default-box">
                    <div className={`box-full`}>
                        <div className="logoBox">
                            <motion.div className={step === 0 || step > 4 ? "logoBoxHideButton" : ""} initial={{ opacity: 0 }} animate={{ opacity: step === 0 || step > 4 ? 0 : 1, transition: { delay: step === 0 ? 0 : 0.35 } }} exit={{ opacity: 0 }}>
                                <div className="backButton" onClick={() => handleBackStep()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                            </motion.div>
                            <div className="logoBoxChildMiddle">
                                <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo pb-0"></img>
                            </div>
                            <div className="logoBoxHideButton">
                                <div className="backButton"></div>
                            </div>
                        </div>
                        <div className="my-4 text-center">
                            {dataApplication.customizacao.form.title.status && <div className="col">
                                <h5><TextWithLineBreaks text={dataApplication.customizacao.form.title.value}></TextWithLineBreaks></h5>
                            </div>}
                            {dataApplication.customizacao.form.description.status && <div className="col">
                                <p><TextWithLineBreaks text={dataApplication.customizacao.form.description.value}></TextWithLineBreaks></p>
                            </div>}
                        </div>
                        {children}
                    </div>
                </div>
            )
    }
};

const StepComponent = ({ step, setStep, formValues, setFormValues, saveFormData }) => {
    const { dataApplication } = useGlobalState()
    const { appState, image, audio, setAudio, props, setProps, setImage } = useAppState();
    const recorderControls = useAudioRecorder()
    const audioRef = useRef(new Audio())
    const notyf = useContext(NotyfContext);
    const formikRef = useRef(null);

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    function registerUser(values) {
        let json = {}
        let dateX = new Date();
        let data_visitacao = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm");
        if (values) {
            if (values.authorization) {
                delete values.authorization
            }
            Object.entries(values).forEach(entry => {
                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
                json[entry[0]] = { 'label': field.label, 'value': entry[1] }
            })
        }
        json = JSON.stringify(json)
        axios.post("/api/eventos/register", {
            evento_id: dataApplication.evento_id,
            json: json,
            data_visitacao: data_visitacao,
        }).then(function (response) {
            if (response.data.message.encripted) {
                localStorage.setItem(dataApplication.token, JSON.stringify(response.data.message.encripted));
                saveVideo(props, response.data.message.visitante_id)
            } else {
                alert('Ocorreu um erro, tente novamente')
                window.location.reload()
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    function handleInitialStep() {
        if (appState.register) {
            setStep((lastStep) => lastStep + 1)
        } else {
            setStep((lastStep) => lastStep + 2)
        }
    }

    function handleSetExpression() {
        if (!props.expression) {
            notyf.open({
                type: "danger",
                message: 'Selecione uma expressão para continuar',
                ripple: true,
                dismissible: true,
            });
        } else {
            setStep((lastStep) => lastStep + 1)
        }
    }

    function handleEndStep() {
        setImage()
        setAudio()
        setStep(0)
        setProps({ 'image': null, 'audio': null, 'expression': null, 'result': null, 'id': null })
        setFormValues()
    }

    const addAudioElement = (blob) => {
        setAudio(blob)
        const url = URL.createObjectURL(blob);
        audioRef.current.src = url;
    };

    const saveMedia = (file, type) => {
        const formData = new FormData();
        formData.append("file", file);
        axios
            .post(`/api/upload/customizacao/${appState.customizacao_id}`, formData)
            .then((response) => {
                let url = domainConfig.imageServer + '/customizacao/' + appState.customizacao_id + '/' + response.data.message

                switch (type) {
                    case 'image':
                        setProps(prevProps => ({
                            ...prevProps,
                            image: url
                        }));
                        break;
                    case 'audio':
                        setProps(prevProps => ({
                            ...prevProps,
                            audio: url
                        }));
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (audio && image && !props.result && step === 5) {
            let photo = dataURItoBlob(image)
            const imagefile = new File([photo], "imagem.jpeg", { type: 'image/jpeg' });
            convertBlobToMP3(audio).then(mp3 => {
                const mp3file = new File([mp3], "audio-f.mp3", { type: 'audio/mp3' });
                saveMedia(mp3file, 'audio')
            });
            saveMedia(imagefile, 'image')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    useEffect(() => {
        if (props.audio && props.image && props.expression && step === 5) {
            if (props.result) {
                if (props.id) {
                    setStep((lastStep) => lastStep + 1)
                } else {
                    registerUser(formValues)
                }
            } else {
                createVideo(props)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    switch (step) {
        default:
        case 0:
            return (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className={`box-full`}>
                        <div className="mb-5">
                            <h5 className="mb-4"><TextWithLineBreaks text={appState.apresentation.firstPage.title}></TextWithLineBreaks></h5>
                            <p><TextWithLineBreaks text={appState.apresentation.firstPage.description}></TextWithLineBreaks></p>
                        </div>
                        <button className="btn primaryColor primaryColorText photoButton mx-auto" onClick={() => handleInitialStep()}>
                            <FontAwesomeIcon icon={faRightLong} size={'xl'} />
                        </button>
                    </div>
                </motion.div>
            )
        case 1:
            return (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="text-center">
                        <h5 className="mb-4"><TextWithLineBreaks text={appState.apresentation.registerPage.title}></TextWithLineBreaks></h5>
                        <p className="mb-4"><TextWithLineBreaks text={appState.apresentation.registerPage.description}></TextWithLineBreaks></p>
                    </div>
                    <div className={`box-full`}>
                        <FormComponent formikRef={formikRef} registerUser={saveFormData}></FormComponent>
                    </div>
                    <div className="mt-3 text-center">
                        <button className="btn primaryColor primaryColorText" onClick={submitFormExternally}>
                            Continuar
                        </button>
                    </div>
                </motion.div>
            )
        case 2:
            return (
                <motion.div className="py-3" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="text-center">
                        <h5 className="mb-4"><TextWithLineBreaks text={appState.apresentation.secondPage.title}></TextWithLineBreaks></h5>
                        <p><TextWithLineBreaks text={appState.apresentation.secondPage.description}></TextWithLineBreaks></p>
                    </div>
                    <div className={`box-full`}>
                        <div className="avatar-box mb-4">
                            <motion.div animate={{ scale: props.expression === 'happy' ? 1.15 : 1, transition: { duration: 0.5 } }}>
                                <FontAwesomeIcon icon={props.expression === 'happy' ? faFaceSmile : faFaceSmileRegular} size={'lg'} onClick={() => setProps(prevProps => ({
                                    ...prevProps,
                                    expression: 'happy'
                                }))} />
                            </motion.div>
                            <motion.div animate={{ scale: props.expression === 'neutral' ? 1.15 : 1, transition: { duration: 0.5 } }}>
                                <FontAwesomeIcon icon={props.expression === 'neutral' ? faFaceMeh : faFaceMehRegular} size={'lg'} onClick={() => setProps(prevProps => ({
                                    ...prevProps,
                                    expression: 'neutral'
                                }))} />
                            </motion.div>
                            <motion.div animate={{ scale: props.expression === 'surprise' ? 1.15 : 1, transition: { duration: 0.5 } }}>
                                <FontAwesomeIcon icon={props.expression === 'surprise' ? faFaceSurprise : faFaceSurpriseRegular} size={'lg'} onClick={() => setProps(prevProps => ({
                                    ...prevProps,
                                    expression: 'surprise'
                                }))} />
                            </motion.div>
                            <motion.div animate={{ scale: props.expression === 'serious' ? 1.15 : 1, transition: { duration: 0.5 } }}>
                                <FontAwesomeIcon icon={props.expression === 'serious' ? faFaceAngry : faFaceAngryRegular} size={'lg'} onClick={() => setProps(prevProps => ({
                                    ...prevProps,
                                    expression: 'serious'
                                }))} />
                            </motion.div>
                        </div>
                        <button className="btn primaryColor primaryColorText photoButton mx-auto" onClick={() => handleSetExpression()}>
                            <FontAwesomeIcon icon={faRightLong} size={'xl'} />
                        </button>
                    </div>
                </motion.div >
            )
        case 3:
            return (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="text-center">
                        <h5 className="mb-4"><TextWithLineBreaks text={appState.apresentation.thirdPage.title}></TextWithLineBreaks></h5>
                        <p><TextWithLineBreaks text={appState.apresentation.thirdPage.description}></TextWithLineBreaks></p>
                    </div>
                    <div className={`box-full`}>
                        <CameraComponent setStep={setStep}></CameraComponent>
                    </div>
                </motion.div>
            )
        case 4:
            return (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="text-center">
                        <h5 className="mb-4"><TextWithLineBreaks text={appState.apresentation.fourthPage.title}></TextWithLineBreaks></h5>
                        <p><TextWithLineBreaks text={appState.apresentation.fourthPage.description}></TextWithLineBreaks></p>
                    </div>
                    <div className={`box-full`}>
                        <AudioRecorder
                            onRecordingComplete={(blob) => addAudioElement(blob)}
                            recorderControls={recorderControls}
                            downloadFileExtension="webm"
                        />
                        <div className="dataElementsContainer my-4">
                            "{appState.apresentation.fourthPage.textSuggestion}"
                        </div>
                        <VoiceComponent setStep={setStep} recorderControls={recorderControls} audioRef={audioRef} ></VoiceComponent>
                    </div>
                </motion.div>
            )
        case 5:
            return (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <h5>Carregando...</h5>
                </motion.div>
            )
        case 6:
            return (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="text-center">
                        <h5 className="mb-4"><TextWithLineBreaks text={appState.apresentation.endPage.title}></TextWithLineBreaks></h5>
                        <p><TextWithLineBreaks text={appState.apresentation.endPage.description}></TextWithLineBreaks></p>
                    </div>
                    <div className={`box-full`}>
                        <QRCode className="bg-white p-2 qrcodeContainer mb-5" value={domainConfig.aplicativos + "/?token=" + dataApplication.token + "&encryptedData=" + props.id.encryptedData + "&iv=" + props.id.iv} />
                        <div>
                            <button className="btn primaryColor primaryColorText mx-auto" onClick={() => handleEndStep()}>
                                Finalizar
                            </button>
                        </div>
                    </div>
                </motion.div>
            )
    }
}

function Step({ step, stepOrder, showOrder }) {
    const { dataApplication } = useGlobalState();
    const status =
        step === stepOrder ? "active" : step > stepOrder ? "complete" : "inactive";

    return (
        <motion.button
            initial={false}
            animate={status}
            variants={{
                inactive: {
                    backgroundColor: "rgba(0,0,0/0)",
                    color: dataApplication.customizacao.primaryColor,
                },
                active: {
                    backgroundColor: dataApplication.customizacao.primaryColor,
                    color: dataApplication.customizacao.primaryColorText,
                },
                complete: {
                    backgroundColor: dataApplication.customizacao.primaryColor,
                    color: dataApplication.customizacao.primaryColorText,
                },
            }}
            className="btn stepSizeButton"
        >
            <span>{showOrder}</span>
        </motion.button>
    );
}

const CameraComponent = ({ setStep }) => {
    const { image, setImage } = useAppState();
    const camera = useRef(null);

    async function handleTakePicture() {
        if (camera && camera.current) {
            try {
                const photo = await camera.current.takePhoto();
                setImage(photo);
            } catch (error) {
                console.error('Error taking photo:', error);
            }
        }
    }

    if (image) {
        return (
            <>
                <div className="cameraContainer mb-5 mt-4">
                    <img src={image} alt="photocam" />
                    <button className="btn primaryColor primaryColorText b-0 photoButtonRefresh" onClick={() => setImage(null)}>
                        <FontAwesomeIcon icon={faRefresh} size={'xl'} />
                    </button>
                </div>
                <div>
                    <button className="btn primaryColor primaryColorText photoButton mx-auto" onClick={() => setStep((lastStep) => lastStep + 1)}>
                        <FontAwesomeIcon icon={faRightLong} size={'xl'} />
                    </button>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="cameraContainer mb-5 mt-4">
                    <Camera ref={camera} />
                </div>
                <button className="btn photoButton primaryColor primaryColorText mx-auto" onClick={() => handleTakePicture()}>
                    <FontAwesomeIcon icon={faCamera} size={'lg'} />
                </button >
            </>
        )
    }
}

const VoiceComponent = ({ setStep, recorderControls, audioRef }) => {
    const { audio, setAudio } = useAppState();
    const [timeLeft, setTimeLeft] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false);

    const handleStartRecording = () => {
        setTimeLeft(10)
        recorderControls.startRecording()
    }

    function startSound() {
        if (audioRef.current) {
            audioRef.current.currentTime = 0
            audioRef.current.play()
            setIsPlaying(true)
            setTimeLeft(10)
        }
    }

    function stopSound() {
        audioRef.current.pause()
        setIsPlaying(false)
    }

    useEffect(() => {
        audioRef.current.onended = function () {
            audioRef.current.pause()
            setIsPlaying(false)
        }
    }, [audioRef])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (timeLeft > 0) {
                setTimeLeft(timeLeft - 1)
            }
        }, 1000)

        if (timeLeft === 0) {
            setTimeout(() => {
                if (recorderControls.isRecording) {
                    recorderControls.stopRecording()
                }
            }, 1000);
        }
        return () => clearInterval(intervalId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft])

    if (!audio) {
        return (
            <>
                <button className="btn photoButton primaryColor primaryColorText mx-auto" onClick={() => recorderControls.isRecording ? "" : handleStartRecording()}>
                    <FontAwesomeIcon icon={recorderControls.isRecording ? faMicrophoneLines : faMicrophone} size={'lg'} />
                </button>
                {recorderControls.isRecording &&
                    <>
                        <h5 className="mt-3 mb-0">00:{timeLeft < 10 ? '0' + timeLeft : timeLeft}</h5>
                        <span className="badge noClickButton mx-auto btn-sm">
                            <FontAwesomeIcon icon={faCircle} className="me-2" />Gravando
                        </span>
                    </>
                }
            </>
        )
    } else {
        return (
            <>
                <div className="mb-5">
                    <button className="btn primaryColor primaryColorText mx-2">
                        <FontAwesomeIcon icon={faRefresh} size={'lg'} onClick={() => setAudio()} />
                    </button>
                    {isPlaying ? (
                        <button className="btn primaryColor primaryColorText mx-2">
                            <FontAwesomeIcon icon={faStop} size={'lg'} onClick={() => stopSound()} />
                            <span className="ms-2 mb-3">00:{timeLeft < 10 ? '0' + timeLeft : timeLeft}</span>
                        </button>
                    ) : (
                        <button className="btn primaryColor primaryColorText mx-2">
                            <FontAwesomeIcon icon={faPlay} size={'lg'} onClick={() => startSound()} />
                            {!isPlaying && <span className="ms-2 mb-3">00:{timeLeft < 10 ? '0' + timeLeft : timeLeft}</span>}
                        </button>
                    )}
                </div>
                <div>
                    <button className="btn primaryColor primaryColorText photoButton mx-auto" onClick={() => setStep((lastStep) => lastStep + 1)}>
                        <FontAwesomeIcon icon={faRightLong} size={'xl'} />
                    </button>
                </div>
            </>
        )
    }
}

const ParticipantHistoryModal = ({ modalData }) => {
    const ItemList = ({ index }) => {
        const dateX = new Date(modalData.history[index].data);
        const date = formatInTimeZone(dateX, "America/Sao_Paulo", " dd/MM HH:mm");

        return (
            <li className="list-group-item" key={'listGroup' + index}>
                <div className="row">
                    <div className="col">
                        <div className="horizontally-centered">
                            <span className="me-2 fs-12">
                                {date}
                            </span>
                        </div>
                        <p className="mb-0">Título: <span className="opacity-75">{modalData.history[index].label}</span></p>
                        <p className="mb-0">Prêmio: <span className="opacity-75">{modalData.history[index].prize}</span></p>
                    </div>
                </div>
            </li>
        )
    }

    return (
        <ul className="list-group text-start my-3">
            {modalData.history && modalData.history.length > 0 ? (
                <div>
                    {modalData.history && Object.keys(modalData.history).map(key => (
                        <ItemList key={'itemList' + key} index={key}></ItemList>
                    ))}
                </div>
            ) : (
                <p className="mt-5 text-center">Não foi encontrado nenhum resultado</p>
            )}
        </ul>
    );
}

export default PhotoboothAIAdmin
