

import React, { useState, useEffect } from 'react'
import QRCode from "react-qr-code";
import { useAppState } from '../context/AppContext.js';
import { TextWithLineBreaks } from "../../../components/Functions.js"
import { useGlobalState } from '../../../hooks/useCustomization';
import { domainConfig } from "../../../assets/config.js"
import { getQuizResult } from './Result'

const End = () => {
    const { handleExit } = useGlobalState();
    const { appState, setResetGame, score: currentScore, setStart, setEndStart } = useAppState();
    // eslint-disable-next-line no-unused-vars
    const [score, setScore] = useState(currentScore)
    const [result] = useState(appState.result && appState.result.length > 0 ? getQuizResult(score.answers, appState) : false);

    useEffect(() => {
        setEndStart(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
            {appState.endText && appState.endText.showImage && appState.endText.showImage.status && <div className="logoBox">
                <img className="resultImage" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.endText.showImage.value} alt=""></img>
            </div>}
            <div className={appState.endText && appState.endText.showImage && appState.endText.showImage.status ? 'mt-4' : ''}>
                {appState.endText.title.status && <div className="col">
                    <h5 className="mb-3"><TextWithLineBreaks text={appState.endText.title.value}></TextWithLineBreaks></h5>
                </div>}
                {appState.endText.description.status && <div className="col">
                    <p className="mb-3"><TextWithLineBreaks text={appState.endText.description.value}></TextWithLineBreaks></p>
                </div>}
                {appState.showScore &&
                    <>
                        <div className="horizontally-centered mb-3">
                            <div className='endQuizzScoreIcon h3'>
                                {/* <FontAwesomeIcon icon={faTrophy} size={'lg'} /> */}
                                {score.value}<br></br>
                                <p className="mb-0">pontos</p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <p>Você acertou {score.totalCorrect} de {appState.questionsPrepared.length} questões</p>
                        </div>
                    </>
                }
            </div>
            {appState.result && appState.result.length > 0 && <QRCode className="bg-white p-3 my-3 qrcodeContainer" value={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + result.url} />}
            <div>
                {appState.showChart && <button className="primaryColor primaryColorText btn mt-3 mx-2" onClick={() => setStart(4)} >Ver gráficos</button>}
                {appState.showRanking && <button className="primaryColor primaryColorText btn mt-3 mx-2" onClick={() => setStart(3)} >Ver ranking</button>}
                {appState.result && appState.result.length > 0 && <button className="primaryColor primaryColorText btn mt-3 mx-2" onClick={() => setStart(2)} >Ver resultado</button>}
                {appState.retry.status && <button className="primaryColor primaryColorText btn mt-3 mx-2" onClick={() => setResetGame((prevState) => prevState + 1)}>{appState.retry.text}</button>}
                {appState.reset?.status && <button className="primaryColor primaryColorText btn mt-3 mx-2" onClick={() => handleExit()}>{appState.reset?.text ? appState.reset.text : 'Finalizar'}</button>}
            </div>
        </>
    )
}

export default End